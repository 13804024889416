import './eoxis_experience.css'
import Config1 from '../../../assets/img/work/eoxis stuff/config v1.jpg'
import Config2 from '../../../assets/img/work/eoxis stuff/config v2.jpg'
import FIN from '../../../assets/img/work/eoxis stuff/fin.png'
import Framework from '../../../assets/img/work/eoxis stuff/frameworks.png'
import Generator1 from '../../../assets/img/work/eoxis stuff/generateur 2.png'
import Generator2 from '../../../assets/img/work/eoxis stuff/generator idee.png'
import InterfacePlaned from '../../../assets/img/work/eoxis stuff/interface planifiee.png'
import Interface1 from '../../../assets/img/work/eoxis stuff/interface1.jpg'
import Interface2 from '../../../assets/img/work/eoxis stuff/interface2.jpg'
import Profil from '../../../assets/img/work/eoxis stuff/profil.jpg'
import Thanks from '../../../assets/img/work/eoxis stuff/thanks.svg'
import {useTranslation} from "react-i18next";
import {Col, Container, Image, Row} from "react-bootstrap";
import Paragraph from "../../misc/paragraph";

const EoxisExperience = () => {
    const { t } = useTranslation('eoxis_experience');

    return <div>
        <Container>
            <Row>
                <Col lg={1}/>
                <Col>
                    <Container>
                        <h1 className="eoxis-main-title">{t("title")}</h1>
                        <Row>
                            <Col xs={12} className={"eoxis-logo-container"}>
                                <Image className="eoxis-logo" src="/assets/img/logos/societes/logo_eoxis.png"/>
                            </Col>
                        </Row>
                        <h2 className={"eoxis-sub-title"}>{t("intro_title_1")}</h2>
                        <p>{t('intro_text_1')}</p>
                        <h2 className={"eoxis-sub-title"}>{t("intro_title_2")}</h2>
                        <Paragraph text={t('intro_text_2')} />
                        <Row className={"eoxis-padding"}>
                            <Col className={"eoxis-logo-container"}>
                                <Image className={"eoxis-big-image"} src={Framework}/>
                                <p>{t('img_txt_1')}</p>
                            </Col>
                        </Row>
                        <h2 className={"eoxis-sub-title"}>{t("eoxis_start_title")}</h2>
                        <Paragraph text={t('eoxis_start_text')} />
                        <Row className={"eoxis-padding"}>
                            <Col className={"eoxis-logo-container"}>
                                <Image className={"eoxis-big-image"} src={Generator2}/>
                                <p>{t('img_txt_2')}</p>
                            </Col>
                        </Row>
                        <h2 className={"eoxis-sub-title"}>{t("project_start_title")}</h2>
                        <Paragraph text={t('project_start_text_1')} />
                        <Row className={"eoxis-padding"}>
                            <Col className={"eoxis-logo-container"}>
                                <Image className={"eoxis-big-image"} src={Generator1}/>
                                <p>{t('img_txt_3')}</p>
                            </Col>
                        </Row>
                        <p>{t('project_start_text_2')}</p>
                        <Row className={"eoxis-padding"}>
                            <Col className={"eoxis-logo-container"}>
                                <Image className={"eoxis-big-image"} src={Config1}/>
                                <p>{t('img_txt_4')}</p>
                            </Col>
                        </Row>
                        <h2 className={"eoxis-sub-title"}>{t("planification_title")}</h2>
                        <Paragraph text={t('planification_text_1')} />
                        <Row className={"eoxis-padding"}>
                            <Col className={"eoxis-logo-container"}>
                                <Image className={"eoxis-big-image"} src={FIN}/>
                                <p>{t('img_txt_5')}</p>
                            </Col>
                        </Row>
                        <Paragraph text={t('planification_text_2')} />
                        <Row className={"eoxis-padding"}>
                            <Col className={"eoxis-logo-container"}>
                                <Image className={"eoxis-big-big-image"} src={InterfacePlaned}/>
                                <p>{t('img_txt_6')}</p>
                            </Col>
                        </Row>
                        <h2 className={"eoxis-sub-title"}>{t("second_poc_title")}</h2>
                        <Paragraph text={t('second_poc_text_1')} />
                        <Row className={"eoxis-small-padding"}>
                            <Col className={"eoxis-logo-container"}>
                                <Image className={"eoxis-big-big-image"} src={Interface1}/>
                                <p>{t('img_txt_7')}</p>
                            </Col>
                        </Row>
                        <Paragraph text={t('second_poc_text_2')} />
                        <Row className={"eoxis-small-padding"}>
                            <Col className={"eoxis-logo-container"}>
                                <Container>
                                    <Row>
                                        <Col lg={6}>
                                            <Image className={"eoxis-big-big-image"} src={Config2}/>
                                        </Col>
                                        <Col lg={6}>
                                            <Image className={"eoxis-big-big-image"} src={Profil}/>
                                        </Col>
                                    </Row>
                                </Container>
                                <p>{t('img_txt_8')}</p>
                            </Col>
                        </Row>
                        <h2 className={"eoxis-sub-title"}>{t("third_poc_title")}</h2>
                        <Paragraph text={t('third_poc_text_1')} />
                        <Row className={"eoxis-small-padding"}>
                            <Col className={"eoxis-logo-container"}>
                                <Image className={"eoxis-big-big-image"} src={Interface2}/>
                                <p>{t('img_txt_9')}</p>
                            </Col>
                        </Row>
                        <p>{t('third_poc_text_2')}</p>
                        <h2 className={"eoxis-sub-title"}>{t("thx_title")}</h2>
                        <Paragraph text={t('thx_text')} />
                        <Row className={"eoxis-small-padding"}>
                            <Col className={"eoxis-logo-container"}>
                                <Image className={"eoxis-big-big-image"} src={Thanks}/>
                            </Col>
                        </Row>
                    </Container>
                </Col>
                <Col lg={1}/>
            </Row>
        </Container>
    </div>
}

export default EoxisExperience;
