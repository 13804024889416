import './presentation_banner.css';
import {Button, Image} from "react-bootstrap";
import { Link } from 'react-router-dom';
import LogoImg from "../../../assets/img/logos/high resolution logo white.png";
import {useTranslation} from "react-i18next";

function PresentationBanner() {
    const { t } = useTranslation('presentation');

    return <div className="banner-content">
        <Image src={LogoImg} className="logo" />
        <h1 className="title">{t('presentation.title')}</h1>
        <div className="buttons">
            <Link to={"/experiences"}>
                <Button variant="outline-light" className="button">{t('presentation.experiences_button')}</Button>
            </Link>
            <Link to={"/education"}>
                <Button variant="outline-light" className="button">{t('presentation.education_button')}</Button>
            </Link>
            <a href="/assets/CV/CV_Matthieu_Raynaud_de_Fitte.pdf" download>
                <Button variant="outline-light" className="button">
                    {t('presentation.download_cv_button')}
                </Button>
            </a>
        </div>
    </div>
}

export default PresentationBanner;
