import "./presentation_quote_2.css";
import {useTranslation} from "react-i18next";
import { Container, Row, Col} from "react-bootstrap";

function PresentationQuote2() {
    const { t } = useTranslation('presentation');

    return <Container className="quote-container2">
        <Row>
            <Col xs={1}/>
            <Col xs={10} className="quote2">
                <h1 className="quote-text2">"{t('presentation.presentation_quote_2')}"</h1>
            </Col>
            <Col xs={1}/>
        </Row>
        <Row>
            <Col xs={1}/>
            <Col xs={10} className="quote-author2">
                <h1 className="quote-author-text2">{t('presentation.presentation_quote_2_author')}</h1>
            </Col>
            <Col xs={1}/>
        </Row>
    </Container>
}

export default PresentationQuote2;
