import './paragraph.css';
import React from "react";

const Paragraph: React.FC<{text: string}> = ({text}) => {
    let paragraphs = text.split('\n');
    return <div>
        {paragraphs.map((paragraph, idx) => {
            return <div className={"small-paragraph-margin"} key={idx}>{paragraph}<br/></div>
        })}
    </div>
}

export default Paragraph;
