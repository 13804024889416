import React from "react";

const getHighlightedText = (paragraph: string, keywords: string[]) => {
    let elements: React.ReactNode[] = [paragraph];
    let index = 0
    keywords.forEach((keyword) => {
        let tempElements: React.ReactNode[] = [];
        elements.forEach((element) => {
            if (typeof element === 'string') {
                const parts = element.split(new RegExp(`(${keyword})`, 'gi'));
                parts.forEach((part) => {
                    if (part === keyword) {
                        tempElements.push(<b key={index}>{part}</b>);
                        index += 1;
                    } else {
                        tempElements.push(part);
                    }
                });
            } else {
                tempElements.push(element);
            }
        });
        elements = tempElements;
    });
    return elements;
};

const BoldParagraphOnKeywords: React.FC<{text: string, keywords: string[]}> = ({text, keywords}) => {
    let paragraphs = text.split('\n');

    return (
        <div>
            {paragraphs.map((paragraph, idx) => (
                <div className="small-paragraph-margin" key={idx}>
                    {getHighlightedText(paragraph, keywords)}
                </div>
            ))}
        </div>
    );
}

export default BoldParagraphOnKeywords;
