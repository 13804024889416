import './education.css'
import {useTranslation} from "react-i18next";
import moment from "moment/moment";
import {Col, Container, Row, Image} from "react-bootstrap";
import React from "react";
import Paragraph from "../misc/paragraph";

interface Education {
    type: string;
    name: string;
    diploma: string;
    diploma_name: string;
    description: string;
    dateStart: string;
    dateEnd: string;
    logoKey: string;
}

const Separator: React.FC<{index: number}> = ({index}) => {
    if (index > 0) {
        return <div className="education-separator"/>
    } else {
        return <div/>
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
function Education() {
    const { t } = useTranslation('education');
    let experiences = [];
    let index = 1;

    while(index < 100) {
        let key = "education." + String(index) + '.name';
        const education = t(key, { returnObjects: true }) as Education;
        if (String(education) === key) break; // no translation found
        experiences.unshift(education);
        index++;
    }

    return <div className={"education-container"}>
        <h1 className={"education-title"}>{t('misc.title')}</h1>
        <Container>
            {experiences.map((education, idx) => {
                index = experiences.length - idx -1;
                // let type: string = t("education." + String(index + 1) + '.type')
                let name: string = t("education." + String(index + 1) + '.name')
                let country: string = t("education." + String(index + 1) + '.country')
                let diploma: string = t("education." + String(index + 1) + '.diploma')
                let diploma_name: string = t("education." + String(index + 1) + '.diploma_name')
                let description: string = t("education." + String(index + 1) + '.description')
                let dateStart = t("education." + String(index + 1) + '.dateStart');
                let dateEnd = t("education." + String(index + 1) + '.dateEnd');
                let logoPath: string = t("education." + String(index + 1) + '.logoPath')
                let duration = "";
                let start = moment(dateStart, "MM/YYYY");
                let end = dateEnd !== "" ? moment(dateEnd, "MM/YYYY") : moment();
                let diffMonths = (end.diff(start, 'months') + 1) % 12;
                let buffYears = ((end.diff(start, 'months') + 1) / 12);
                let diffYears = buffYears - (buffYears % 1);
                if (diffMonths === 0) {
                    duration = "";
                } else if (diffMonths === 1) {
                    duration = "1 " + t("misc.month");
                } else {
                    duration = diffMonths + " " + t("misc.months")
                }
                if (diffYears === 1) {
                    duration = "1 " + t("misc.year") + " " + duration;
                } else if (diffYears > 1) {
                    duration = diffYears + " " + t("misc.years") + " " + duration;
                }
                return <div key={idx}>
                    <Row className={"education-row"}>
                        <Col md={5}>
                            <Container className={"no-padding-education-container"}>
                                <Row>
                                    <Col lg={6}>
                                        <Image src={logoPath} className={"logo-education"} loading={"lazy"}/>
                                    </Col>
                                    <Col lg={6}>
                                        <h6 className={"small-education-title"}>{name}</h6>
                                        <p className={"education-details"}>{diploma + " " + diploma_name}</p>
                                        <p className={"education-details"}>{country}</p>
                                        <p className={"education-details"}>{dateStart} - {(dateEnd === "") ? t("misc.present") : dateEnd} - {duration}</p>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                        <Col md={7}>
                            <Paragraph text={description}/>
                        </Col>
                   </Row>
                    <Separator index={index}/>
               </div>
            })}
        </Container>
    </div>
}

export default Education;
