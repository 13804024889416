import './thales_experience.css'
import AsterSystem from '../../../assets/img/work/thales stuff/800px-Aster_30_system.jpg'
import Damocles from '../../../assets/img/work/thales stuff/damocles.jpg'
import DevLife from '../../../assets/img/work/thales stuff/developer_life.png'
import Drone from '../../../assets/img/work/thales stuff/drone.jpg'
import PO from '../../../assets/img/work/thales stuff/product_owner.png'
import SM from '../../../assets/img/work/thales stuff/scrum_master.png'
import SurveillanceSystem from '../../../assets/img/work/thales stuff/surveillance_system.jpg'
import Thanks from '../../../assets/img/work/thales stuff/thanks.svg'
import {useTranslation} from "react-i18next";
import Paragraph from "../../misc/paragraph";
import {Container, Col, Row, Image} from "react-bootstrap";
import GoogleApiKey from "../../config";

const ThalesExperience = () => {
    const { t } = useTranslation('thales_experience');

    return <div>
        <Container>
            <Row>
                <Col lg={1}/>
                <Col lg={10}>
                    <Container>
                        <Row>
                            <h1 className="thales-main-title">{t("title")}</h1>
                            <Col xs={12} className={"thales-logo-container"}>
                                <Image className="thales-logo" src="/assets/img/logos/societes/logo_thales.png"/>
                            </Col>
                            <p>{t('intro')}</p>
                            <h2 className={"thales-sub-title"}>{t("prez_title")}</h2>
                            <Paragraph text={t('prez_text')}/>
                            <h4 className={"thales-sub-sub-title"}>{t('prez_pics')}</h4>
                        </Row>
                        <Row className={"thales-logo-container"}>
                            <Col xl={4}>
                                <Image className="thales-small-image" src={AsterSystem}/>
                                <p>{t('prez_pic_1')}</p>
                            </Col>
                            <Col xl={4}>
                                <Image className="thales-small-image" src={Damocles}/>
                                <p>{t('prez_pic_2')}</p>
                            </Col>
                            <Col xl={4}>
                                <Image className="thales-small-image" src={Drone}/>
                                <p>{t('prez_pic_3')}</p>
                            </Col>
                        </Row>
                        <Row>
                            <h2 className={"thales-sub-title"}>{t('surveillance_title')}</h2>
                            <Col className={"thales-logo-container"}>
                                <Image  className={"thales-big-image"} src={SurveillanceSystem}/>
                            </Col>
                            <h4 className={"thales-sub-sub-title"}>{t('surveillance_sub_title1')}</h4>
                            <p>{t('surveillance_txt_1')}</p>
                            <h4 className={"thales-sub-sub-title"}>{t('surveillance_sub_title2')}</h4>
                            <Paragraph text={t('surveillance_txt_2')}/>
                            <h4 className={"thales-sub-sub-title"}>{t('surveillance_sub_title3')}</h4>
                            <p>{t('surveillance_txt_3')}</p>
                        </Row>
                        <Row className={"thales-logo-container"}>
                            <Col xl={12}>
                                <iframe
                                    title={"Thales map"}
                                    className={"thales-map"}
                                    loading="lazy"
                                    allowFullScreen
                                    referrerPolicy="no-referrer-when-downgrade"
                                    src={"https://www.google.com/maps/embed/v1/place?key="+GoogleApiKey.key+"&q=Thales-SIX-GTS-France,Velizy"}>
                                </iframe>
                            </Col>
                        </Row>
                        <Row>
                            <h2 className={"thales-sub-title"}>{t('PO_title')}</h2>
                        </Row>
                        <Row>
                            <Col className={"thales-logo-container"} xs={12}>
                                <Image className={"thales-big-image"} src={PO}/>
                            </Col>
                        </Row>
                        <Row>
                            <Paragraph text={t('PO_text')}/>
                            <h2 className={"thales-sub-title"}>{t('dev_title')}</h2>
                            <Col className={"thales-logo-container"}>
                                <Image className={"thales-big-image"} src={DevLife}/>
                            </Col>
                            <Paragraph text={t('dev_text')}/>
                            <h2 className={"thales-sub-title"}>{t('SM_title')}</h2>
                            <Col className={"thales-logo-container"}>
                                <Image className={"thales-image"} src={SM}/>
                            </Col>
                            <Paragraph text={t('SM_text')}/>
                            <h2 className={"thales-sub-title"}>{t('thank_you_title')}</h2>
                            <Col className={"thales-logo-container"}>
                                <Image className={"thales-big-image"} src={Thanks}/>
                            </Col>
                            <Paragraph text={t('thank_you_text')}/>
                        </Row>
                    </Container>
                </Col>
                <Col lg={1}/>
            </Row>
        </Container>
    </div>
}

export default ThalesExperience;
