import './presentation.css'
// import {useTranslation} from "react-i18next";
import PresentationBanner from "./presentation_banner/presentation_banner";
import PresentationText from "./presentation_text/presentation_text";
import PresentationQuote1 from "./presentation_quote_1/presentation_quote_1";
import PresentationQuote2 from "./presentation_quote_2/presentation_quote_2";
import Skills from "./skills/skills";
import Links from "./links/links";
import SocialLinks from "./social_links/social_links";

function Presentation() {
    // const { t, i18n } = useTranslation('presentation');

    return <div className="banner">
        <PresentationBanner />
        <PresentationText />
        <PresentationQuote1 />
        <Skills />
        <PresentationQuote2 />
        <Links />
        <SocialLinks />
    </div>
}


export default Presentation;
