import "./list.css"

const List: React.FC<{text: string, numbered: boolean}> = ({text, numbered}) => {
    let paragraphs = text.split('\n');
    if (numbered) {
        return <ol className={"custom-list"}>
            {paragraphs.map((paragraph, idx) => {
                return <li className={"small-paragraph-margin"} key={idx}>{paragraph}<br/></li>
            })}
        </ol>
    } else {
        return <ul className={"custom-list"}>
            {paragraphs.map((paragraph, idx) => {
                return <li className={"small-paragraph-margin"} key={idx}>{paragraph}<br/></li>
            })}
        </ul>
    }
}

export default List;
