import "./selfee_experience.css"
import {useTranslation} from "react-i18next";
import {Col, Container, Image, Row} from "react-bootstrap";
import Paragraph from "../../misc/paragraph";
import YoutubeEmbeded from "../../misc/youtube_embeded";
import Thanks from '../../../assets/img/work/selfee stuff/thanks.svg'
import Archi from '../../../assets/img/work/selfee stuff/archi.png'
import Integration from '../../../assets/img/work/selfee stuff/integration.png'
import TestStrategy from '../../../assets/img/work/selfee stuff/test strategy.png'


const SelfeeExperience = () => {
    const { t } = useTranslation('selfee_experience');

    return <div>
        <Container>
            <Row>
                <Col lg={1}/>
                <Col lg={10}>
                    <Container>
                        <Row>
                            <h2 className="selfee-main-title">{t("title")}</h2>
                        </Row>
                        <Row>
                            <Col xs={12} className={"selfee-logo-container"}>
                                <Image className="selfee-logo" src="/assets/img/logos/societes/selfee_logo.png"/>
                            </Col>
                        </Row>
                        <Row>
                            <h3 className={"selfee-sub-title"}>{t("QA_title_1")}</h3>
                            <Paragraph text={t('QA_text_1a')} />
                            <h3 className={"selfee-sub-title selfee-small-padding"}>{t("QA_title_2")}</h3>
                            <Paragraph text={t('QA_text_2a')} />
                            <Paragraph text={t('QA_text_2b')} />
                            <Paragraph text={t('QA_text_2c')} />
                            <Paragraph text={t('QA_text_2d')} />
                        </Row>
                        <Row className={"selfee-small-padding"}>
                            <Col className={"selfee-logo-container selfee-no-margin"}>
                                <YoutubeEmbeded className={"selfee-embeded-video"} embedId={"ienoSbONyhw"}/>
                            </Col>
                        </Row>
                        <Row>
                            <h3 className={"selfee-sub-title selfee-small-padding"}>{t("QA_title_3")}</h3>
                            <Paragraph text={t('QA_text_3a')}/>
                            <h3 className={"selfee-sub-title selfee-small-padding"}>{t("QA_title_4")}</h3>
                            <Paragraph text={t('QA_text_4a')}/>
                            <Paragraph text={t('QA_text_4b')}/>
                            <Paragraph text={t('QA_text_4c')}/>
                            <Paragraph text={t('QA_text_4d')}/>
                        </Row>
                        <Row className={"selfee-small-padding"}>
                            <Col xs={12} className={"selfee-logo-container"}>
                                <Image className="selfee-big-big-image" src={Archi}/>
                            </Col>
                        </Row>
                        <Row>
                            <h3 className={"selfee-sub-title selfee-small-padding"}>{t("QA_title_5")}</h3>
                            <Paragraph text={t('QA_text_5a')}/>
                            <Paragraph text={t('QA_text_5b')}/>
                            <Paragraph text={t('QA_text_5c')}/>
                            <Paragraph text={t('QA_text_5d')}/>
                        </Row>
                        <Row className={"selfee-small-padding"}>
                            <Col xs={12} className={"selfee-logo-container"}>
                                <Image className="selfee-big-big-image" src={Integration}/>
                            </Col>
                        </Row>
                        <Row>
                            <Paragraph text={t('QA_text_5e')}/>
                            <Paragraph text={t('QA_text_5f')}/>
                        </Row>
                        <Row className={"selfee-small-padding"}>
                            <Col xs={12} className={"selfee-logo-container"}>
                                <Image className="selfee-big-image" src={TestStrategy}/>
                            </Col>
                        </Row>
                        <Row>
                            <Paragraph text={t('QA_text_5g')}/>
                            <h3 className={"selfee-sub-title selfee-small-padding"}>{t("QA_title_6")}</h3>
                            <Paragraph text={t('QA_text_6a')}/>
                        </Row>
                        <Row className={""}>
                            <Col xs={12} className={"selfee-logo-container"}>
                                <Image className="selfee-big-big-image" src={Thanks}/>
                            </Col>
                        </Row>
                    </Container>
                </Col>
                <Col lg={1}/>
            </Row>
        </Container>
    </div>
}

export default SelfeeExperience;
