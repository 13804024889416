import './societe_generale_experience.css'
import {useTranslation} from "react-i18next";
import {Col, Container, Image, Row} from "react-bootstrap";
import Paragraph from "../../misc/paragraph";
import BoldParagraphOnKeywords from "../../misc/bold_paragraph";
import List from "../../misc/list";
import TestDoctorUI from '../../../assets/img/work/sg stuff/output_examples.png'
import TestDoctorConsole from '../../../assets/img/work/sg stuff/console_output.png'
import Construction from '../../../assets/img/work/sg stuff/construction.jpg'
import Inventory from '../../../assets/img/work/sg stuff/inventory.png'
import QAGraph from '../../../assets/img/work/sg stuff/QAGraph.png'
import Abandoned from '../../../assets/img/work/sg stuff/abandoned.jpg'
import Funny from '../../../assets/img/work/sg stuff/clown_refactorisation.png'
import TechDebt from '../../../assets/img/work/sg stuff/spaghetti code.png'
import TechDebtSolution from '../../../assets/img/work/sg stuff/spaghetti code fix.png'
import TechDebtSolution2 from '../../../assets/img/work/sg stuff/spaghetti code round 2.png'
import Workflow from '../../../assets/img/work/sg stuff/workflow.png'
import Derailed from '../../../assets/img/work/sg stuff/derailed.jpg'
import Thanks from '../../../assets/img/work/sg stuff/thanks.svg'

const SocieteGeneraleExperience = () => {
    const { t } = useTranslation('societe_generale');

    return <div>
        <Container>
            <Row>
                <Col lg={1}/>
                <Col lg={10}>
                    <Container>
                        <Row>
                            <h1 className="sg-main-title">{t("sc.title")}</h1>
                        </Row>
                        <Row>
                            <Col xs={12} className={"sg-logo-container"}>
                                <Image className="sg-logo" src="/assets/img/logos/societes/Logo_societe_generale.png"/>
                            </Col>
                        </Row>
                        <Row>
                            <h2 className={"sg-sub-title"}>{t("sc.intro_title")}</h2>
                            <Paragraph text={t('sc.intro_text')} />
                            <h4 className={"sg-sub-sub-title"}>{t("sc.QA_subtitle_1")}</h4>
                            <Paragraph text={t('sc.QA_text_1')} />
                            <h4 className={"sg-sub-sub-title"}>{t("sc.QA_subtitle_2")}</h4>
                            <Paragraph text={t('sc.QA_text_2a')} />
                        </Row>
                        <Row className={"sg-small-padding"}>
                            <Col className={"sg-logo-container sg-no-margin"}>
                                <Image className={"sg-big-image"} src={Abandoned}/>
                            </Col>
                        </Row>
                        <Row>
                            <Paragraph text={t('sc.QA_text_2b')} />
                            <h4 className={"sg-sub-sub-title"}>{t("sc.QA_subtitle_3")}</h4>
                            <Paragraph text={t('sc.QA_text_3a')} />
                        </Row>
                        <Row className={"sg-small-padding"}>
                            <Col className={"sg-logo-container"}>
                                <Image className={"sg-big-image"} src={QAGraph}/>
                                <p className={"sg-image-description"}>{t('sc.image_description_c')}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Paragraph text={t('sc.QA_text_3b')} />
                            <h4 className={"sg-sub-sub-title"}>{t("sc.QA_subtitle_4")}</h4>
                            <Paragraph text={t('sc.QA_text_4a')} />
                        </Row>
                        <Row className={"sg-small-padding"}>
                            <Col className={"sg-logo-container"}>
                                <Image className={"sg-big-big-image"} src={TestDoctorUI}/>
                                <p className={"sg-image-description"}>{t('sc.image_description_a')}</p>
                                <Image className={"sg-big-big-image"} src={TestDoctorConsole}/>
                                <p className={"sg-image-description"}>{t('sc.image_description_b')}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Paragraph text={t('sc.QA_text_4b')} />
                            <Paragraph text={t('sc.QA_text_4c')} />
                            <BoldParagraphOnKeywords text={t('sc.QA_text_4d')} keywords={["Me :", "Them :", "Eux :", "Moi :"]}/>
                            <BoldParagraphOnKeywords text={t('sc.QA_text_4e')} keywords={["Me :", "Them :", "Eux :", "Moi :"]}/>
                            <BoldParagraphOnKeywords text={t('sc.QA_text_4f')} keywords={["Me :", "Them :", "Eux :", "Moi :"]}/>
                            <Paragraph text={t('sc.QA_text_4g')} />
                        </Row>
                        <Row>
                            <h4 className={"sg-sub-sub-title"}>{t("sc.QA_subtitle_5")}</h4>
                        </Row>
                        <Row>
                            <Col className={"sg-logo-container sg-no-margin"}>
                                <Image className={"sg-image"} src={Inventory}/>
                            </Col>
                        </Row>
                        <Row>
                            <Paragraph text={t('sc.QA_text_5a')} />
                            <List text={t('sc.QA_text_5b')} numbered={false}/>
                            <Paragraph text={t('sc.QA_text_5c')} />
                            <List text={t('sc.QA_text_5d')} numbered={false}/>
                            <h4 className={"sg-sub-sub-title"}>{t("sc.QA_subtitle_6")}</h4>
                        </Row>
                        <Row>
                            <Col className={"sg-logo-container sg-no-margin"}>
                                <Image className={"sg-big-image"} src={Construction}/>
                            </Col>
                        </Row>
                        <Row>
                            <Paragraph text={t('sc.QA_text_6a')} />
                            <Image className={"sg-big-image sg-small-margin"} src={Workflow}/>
                            <Paragraph text={t('sc.QA_text_6b')} />
                            <h4 className={"sg-sub-sub-title"}>{t("sc.QA_subtitle_7")}</h4>
                            <Paragraph text={t('sc.QA_text_7a')} />
                        </Row>
                        <Row>
                            <Col className={"sg-logo-container"}>
                                <Image className={"sg-big-image sg-small-margin"} src={Funny}/>
                            </Col>
                        </Row>
                        <Row>
                            <Paragraph text={t('sc.QA_text_7b')} />
                            <List text={t('sc.QA_text_7c')} numbered={false}/>
                            <Paragraph text={t('sc.QA_text_7d')} />
                            <h4 className={"sg-sub-sub-title"}>{t("sc.QA_subtitle_8")}</h4>
                            <Paragraph text={t('sc.QA_text_8a')} />
                            <Paragraph text={t('sc.QA_text_8b')} />
                        </Row>
                        <Row>
                            <Col className={"sg-logo-container"}>
                                <Image className={"sg-big-image sg-small-margin"} src={TechDebt}/>
                            </Col>
                        </Row>
                        <Row>
                            <Paragraph text={t('sc.QA_text_8c')} />
                            <Paragraph text={t('sc.QA_text_8d')} />
                            <h4 className={"sg-sub-sub-title"}>{t("sc.QA_subtitle_9")}</h4>
                            <Paragraph text={t('sc.QA_text_9a')} />
                            <Paragraph text={t('sc.QA_text_9b')} />
                        </Row>
                        <Row>
                            <Col className={"sg-logo-container"}>
                                <Image className={"sg-big-image sg-small-margin"} src={TechDebtSolution}/>
                            </Col>
                        </Row>
                        <Row>
                            <Paragraph text={t('sc.QA_text_9c')} />
                            <Paragraph text={t('sc.QA_text_9d')} />
                            <Paragraph text={t('sc.QA_text_9e')} />
                            <h4 className={"sg-sub-sub-title"}>{t("sc.QA_subtitle_10")}</h4>
                        </Row>
                        <Row>
                            <Col className={"sg-logo-container sg-no-margin"}>
                                <Image className={"sg-big-image sg-small-margin"} src={Derailed}/>
                            </Col>
                        </Row>
                        <Row>
                            <Paragraph text={t('sc.QA_text_10a')}/>
                            <Paragraph text={t('sc.QA_text_10b')}/>
                            <List text={t('sc.QA_text_10c')} numbered={false}/>
                        </Row>
                        <Row>
                            <Col className={"sg-logo-container"}>
                                <Image className={"sg-big-big-image sg-small-margin"} src={TechDebtSolution2}/>
                            </Col>
                        </Row>
                        <Row>
                            <Paragraph text={t('sc.QA_text_10d')}/>
                            <h4 className={"sg-sub-sub-title"}>{t("sc.QA_subtitle_11")}</h4>
                            <Paragraph text={t('sc.QA_text_11a')}/>
                            <Paragraph text={t('sc.QA_text_11b')}/>
                            <Paragraph text={t('sc.QA_text_11c')}/>
                        </Row>
                        <Row>
                            <Col className={"sg-logo-container"}>
                                <Image className={"sg-big-big-image sg-small-margin"} src={Thanks}/>
                            </Col>
                        </Row>
                        {/* references for the used pictures*/}
                        <Row>
                            <br/>
                            <p className={"sg-small-image-description sg-no-margin"}>
                                {t('misc.credits_a')} <a href="https://www.freepik.com/free-vector/destroyed-city-buildings-after-war-earthquake_10108808.htm#query=ruined%20building&position=4&from_view=keyword&track=ais">{t('misc.credits_b')}</a>{t('misc.credits_c')}<a href="https://www.freepik.com/free-vector/flat-engineering-construction-illustrated_13107137.htm#query=building%20site&position=0&from_view=keyword&track=ais">{t('misc.credits_d')}</a>{t('misc.credits_e')}
                            </p>
                        </Row>
                    </Container>
                </Col>
                <Col lg={1}/>
            </Row>
        </Container>
    </div>
}

export default SocieteGeneraleExperience;
