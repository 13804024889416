import './layout.css'
import Header from '../header/Header'
import { Outlet } from "react-router-dom";
import Footer from "../footer/footer";
import {useEffect} from "react";

function Layout() {
    useEffect(() => {
        document.title = 'Matthieu Raynaud de Fitte';
    }, []);

    return <div>
        <Header />
        <Outlet />
        <Footer />
    </div>
}

export default Layout;
