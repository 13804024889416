import "./footer.css";
import {Container, Image, Nav, Row, Col} from "react-bootstrap";
import EnImg from "../../assets/img/flags/en_flag.png";
import FrImg from "../../assets/img/flags/fr_flag.png";
import {useTranslation} from "react-i18next";

function Footer() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { t, i18n } = useTranslation('presentation');
    const changeLanguage = (language: string) => {
        i18n.changeLanguage(language);
    };

    return <footer className={"footer-container"}>
        <Container className="footer">
            <Row className={"footer-content"}>
                <Col md={4}></Col>
                <Col xs={4} className={"footer-text-container"}>
                    <p className={"footer-text"} >© Copyright 2023</p>
                </Col>
                <Col xs={4}>
                    <Nav className="ml-auto social-links">
                        <div className="social-links">
                            <Nav.Link href="https://www.linkedin.com/in/matthieu-raynaud-de-fitte/" target="_blank"><i className="fab fa-linkedin"></i></Nav.Link>
                            <Nav.Link href="https://github.com/Hellfire01" target="_blank"><i className="fab fa-github"></i></Nav.Link>
                            <Nav.Link href="https://stackoverflow.com/users/4817370/matthieu-raynaud-de-fitte" target="_blank"><i className="fab fa-stack-overflow"></i></Nav.Link>
                            <Image onClick={() => changeLanguage('en')} src={EnImg} />
                            <Image onClick={() => changeLanguage('fr')} src={FrImg} />
                        </div>
                    </Nav>
                </Col>
            </Row>
        </Container>
    </footer>
}

export default Footer;
