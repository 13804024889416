import React from 'react';
import './experiences.css'
import { Accordion, Container, Row, Col, Button, Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {Link} from "react-router-dom";
import moment from 'moment';
import Paragraph from "../misc/paragraph";

interface Experience {
    type: string;
    society: string;
    title: string;
    techs: string;
    dateStart: string;
    dateEnd: string;
    description: string;
    logoPath: string;
    internalLink: string;
}

function Experiences() {
    const { t } = useTranslation('experiences');
    let experiences = [];
    let index = 1;

    while(index < 100) {
        let key = "experiences." + String(index) + '.society';
        const experience = t(key, { returnObjects: true }) as Experience;
        if (String(experience) === key) break; // no translation found
        experiences.unshift(experience);
        index++;
    }

    type GetMoreProps = {
        link: string;
    };

    const GetMore: React.FC<GetMoreProps> = ({ link }) => {
        if (link !== "") {
            return (<p>
                {t('misc.rapport')}
                <Link to={link}>
                    <Button variant="outline-dark" className={"experiences-button"}>
                        <span className={"experience-button-text"}>{t('misc.button')}</span>
                    </Button>
                </Link>
            </p>)
        } else {
            return <span/>
        }
    }

    return <div>
        <h1 className={"experiences-title"}>{t('misc.title')}</h1>
        <Container>
            <Row>
                <Accordion defaultActiveKey={[String(experiences.length)]} alwaysOpen flush>
                    {experiences.map((exp, idx) => {
                        index = experiences.length - idx -1;
                        let link: string = t("experiences." + String(index + 1) + '.internalLink')
                        let logoPath: string = t("experiences." + String(index + 1) + '.logoPath')
                        let dateStart = t("experiences." + String(index + 1) + '.dateStart');
                        let dateEnd = t("experiences." + String(index + 1) + '.dateEnd');
                        let duration = "";
                        let start = moment(dateStart, "MM/YYYY");
                        let end = dateEnd !== "" ? moment(dateEnd, "MM/YYYY") : moment();
                        let diffMonths = (end.diff(start, 'months') + 1) % 12;
                        let buffYears = ((end.diff(start, 'months') + 1) / 12);
                        let diffYears = buffYears - (buffYears % 1);
                        if (diffMonths === 0) {
                            duration = "";
                        } else if (diffMonths === 1) {
                            duration = "1 " + t("misc.month");
                        } else {
                            duration = diffMonths + " " + t("misc.months")
                        }
                        if (diffYears === 1) {
                            duration = "1 " + t("misc.year") + " " + duration;
                        } else if (diffYears > 1) {
                            duration = diffYears + " " + t("misc.years") + " " + duration;
                        }
                        return (
                            <Accordion.Item eventKey={String(index + 1)} key={String(index + 1)}>
                                <Accordion.Header>
                                    <Container>
                                        <Row>
                                            <Col xs={3} >
                                                <Image className={"small-experience-logo"} src={logoPath} loading={"lazy"}/>
                                            </Col>
                                            <Col xs={3}>
                                                {t("experiences." + String(index + 1) + '.society')}
                                            </Col>
                                            <Col xs={3}>
                                                {dateStart} - {(dateEnd === "") ? t("misc.present") : dateEnd}
                                            </Col>
                                            <Col xs={3}>
                                                {duration}
                                            </Col>
                                        </Row>
                                    </Container>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <Container>
                                        <Row>
                                            <Col md={3}>
                                                <h5 className={"experiences-sub-title"}>
                                                    {t("experiences." + String(index + 1) + '.title')}
                                                </h5>
                                                <p className={"experiences-bold"}>
                                                    {t("experiences." + String(index + 1) + '.type')}
                                                </p>
                                            </Col>
                                            <Col md={9}>
                                                <p className={"experiences-bold"}>
                                                    {t("experiences." + String(index + 1) + '.techs')}
                                                </p>
                                                <Paragraph text={t("experiences." + String(index + 1) + '.description')}/>
                                                <GetMore link={link}/>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Accordion.Body>
                            </Accordion.Item>
                        )
                    })}
                </Accordion>
            </Row>
        </Container>
    </div>
}

export default Experiences;
