import "./skills.css"
import {Col, Row, Container } from 'react-bootstrap';
import {useTranslation} from "react-i18next";
import { ImageData } from "./imageData"
import Paragraph from "../../misc/paragraph";

import Python from '../../../assets/img/logos/skills/Python.png';
import Pyqt from '../../../assets/img/logos/skills/pyqt.png'
import Ruby from '../../../assets/img/logos/skills/ruby.png'
import CSharp from '../../../assets/img/logos/skills/C-sharp.png'
import CPP from '../../../assets/img/logos/skills/Cplusplus.png'
import Postgre from '../../../assets/img/logos/skills/postgresql.png'
import PHP from '../../../assets/img/logos/skills/PHP.png'
import HTML from '../../../assets/img/logos/skills/HTML5.png'
import JS from '../../../assets/img/logos/skills/JS.png'
import CSS from '../../../assets/img/logos/skills/CSS3.png'
import NodeJS from '../../../assets/img/logos/skills/nodejs.png'
import Angular from '../../../assets/img/logos/skills/angular.png'
import Bootstrap from '../../../assets/img/logos/skills/bootstrap.png'
import Bash from '../../../assets/img/logos/skills/bash.png'
import Java from '../../../assets/img/logos/skills/java.png'
import Spring from '../../../assets/img/logos/skills/spring.png'
import React from '../../../assets/img/logos/skills/react.png'
import Terraform from '../../../assets/img/logos/skills/Terraform.png'
import Ansible from '../../../assets/img/logos/skills/Ansible.png'
import Sqlite from '../../../assets/img/logos/skills/sqlite.png'

import Git from '../../../assets/img/logos/skills/git.png'
import Github from '../../../assets/img/logos/skills/github.png'
import Gitlab from '../../../assets/img/logos/skills/gitlab.png'
import Ubuntu from '../../../assets/img/logos/skills/ubuntu.png'
import Archlinux from '../../../assets/img/logos/skills/archlinux.png'
import Windows from '../../../assets/img/logos/skills/windows.png'
import Raspberry from '../../../assets/img/logos/skills/raspberry.png'
import QTDesigner from '../../../assets/img/logos/skills/pyqt.png'
import Jetbrains from '../../../assets/img/logos/skills/jetbrains.png'
import Unity3d from '../../../assets/img/logos/skills/unity3d.png'
import Photoshop from '../../../assets/img/logos/skills/photoshop.png'
import Office from '../../../assets/img/logos/skills/office.png'
import RabbitMQ from '../../../assets/img/logos/skills/RabbitMQ.png'
import Postman from '../../../assets/img/logos/skills/postman.svg'
import ChatGPT from '../../../assets/img/logos/skills/ChatGPT.svg'
import Copilot from '../../../assets/img/logos/skills/Copilot.png'
import Behave from '../../../assets/img/logos/skills/behave.png'
import Pytest from '../../../assets/img/logos/skills/pytest.png'
import wiremock from '../../../assets/img/logos/skills/wiremock.png'
import chaos_mesh from '../../../assets/img/logos/skills/chaos_mesh.png'


export const languages: ImageData[] = [
    { src: Python, description: 'Python 3' },
    { src: Pyqt, description: 'PyQT' },
    { src: Ruby, description: 'Ruby' },
    { src: CSharp, description: 'C#' },
    { src: CPP, description: 'C++' },
    { src: Sqlite, description: 'SQLite3' },
    { src: Postgre, description: 'PostgreSQL' },
    { src: PHP, description: 'PHP' },
    { src: HTML, description: 'HTML' },
    { src: JS, description: 'JS' },
    { src: CSS, description: 'CSS' },
    { src: NodeJS, description: 'NodeJS' },
    { src: React, description: 'React' },
    { src: Angular, description: 'Angular' },
    { src: Bootstrap, description: 'Bootstrap' },
    { src: Bash, description: 'Bash' },
    { src: Java, description: 'Java' },
    { src: Spring, description: 'Spring' },
    { src: Terraform, description: 'Terraform' },
    { src: Ansible, description: 'Ansible' },
];

export const tools: ImageData[] = [
    { src: Git, description: 'Git' },
    { src: Github, description: 'Github' },
    { src: Gitlab, description: 'Gitlab' },
    { src: Ubuntu, description: 'Ubuntu' },
    { src: Archlinux, description: 'Archlinux' },
    { src: Raspberry, description: 'Raspberry' },
    { src: Windows, description: 'Windows' },
    { src: QTDesigner, description: 'QTDesigner' },
    { src: Jetbrains, description: 'Jetbrains' },
    { src: Unity3d, description: 'Unity3d' },
    { src: Photoshop, description: 'Photoshop' },
    { src: Office, description: 'Office' },
    { src: RabbitMQ, description: 'RabbitMQ' },
    { src: Postman, description: 'Postman' },
    { src: Copilot, description: 'Copilot' },
    { src: ChatGPT, description: 'Chat GPT' },
    { src : Behave, description: 'Behave' },
    { src : Pytest, description: 'Pytest' },
    { src : wiremock, description: 'Wiremock' },
    { src : chaos_mesh, description: 'Chaos Mesh'}
];

function Skills() {
    const { t } = useTranslation('presentation');

    return <div id={"skills"}>
        <div className="skill-title-div">
            <h1 className="skill-title">{t('presentation.skills_title')}</h1>
        </div>
        <Container>
            <Row>
                <div className="skill-title-div">
                    <h1 className="sub-skill-title">{t('presentation.skills_details_title')}</h1>
                </div>
                <Paragraph text={t('presentation.skills_details_1') + '\n' + t('presentation.skills_details_2') + '\n' + t('presentation.skills_details_3')}/>
            </Row>
            <Row className={"skills-small-padding"}>
                <div className="skill-title-div">
                    <h1 className="sub-skill-title">{t('presentation.languages_title')}</h1>
                </div>
            </Row>
            <Row className={"skill-container"}>
                {languages.map((image, index) => (
                    <Col xs={3} sm={3} md={2} lg={2} xl={1} key={index}>
                        <div className='skill-image-container'>
                            <div className='image-container-skill'>
                                <img src={image.src} alt={image.description} className='skill-image' />
                            </div>
                            <p className='skill-image-description'>{image.description}</p>
                        </div>
                    </Col>
                ))}
            </Row>
            <Row className={"skills-small-padding"}>
                <div className="skill-title-div">
                    <h1 className="sub-skill-title">{t('presentation.tools_title')}</h1>
                </div>
            </Row>
            <Row className={"skills-small-padding skill-container"}>
                {tools.map((image, index) => (
                    <Col xs={3} sm={3} md={2} lg={2} xl={1} key={index}>
                        <div className='skill-image-container'>
                            <div className='image-container-skill'>
                                <img src={image.src} alt={image.description} className='skill-image' />
                            </div>
                            <p className='skill-image-description'>{image.description}</p>
                        </div>
                    </Col>
                ))}
            </Row>
        </Container>
    </div>
}

export default Skills;
