import { Navbar, Nav, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { useTranslation } from 'react-i18next';
import EnImg from '../../assets/img/flags/en_flag.png';
import FrImg from '../../assets/img/flags/fr_flag.png';
import LogoImg from '../../assets/img/logos/high resolution logo black.png';
import './Header.css'

function Header() {
    const { t, i18n } = useTranslation('header');

    const changeLanguage = (language: string) => {
        i18n.changeLanguage(language);
    };
    return (
        <Navbar collapseOnSelect expand="lg" bg="light" className="navbar">
            <Link to="/">
                <Navbar.Brand className="navbar-brand">
                    <Image src={LogoImg} className="logo" />
                </Navbar.Brand>
            </Link>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <div className="nav-links-container">
                    <Nav className="mr-auto">
                        <Nav.Link as={HashLink} to="/">{t('navbar.presentation')}</Nav.Link>
                        <Nav.Link as={HashLink} to="/skills#skills">{t('navbar.skills')}</Nav.Link>
                        <Nav.Link as={HashLink} to="/experiences">{t('navbar.experiences')}</Nav.Link>
                        <Nav.Link as={HashLink} to="/education">{t('navbar.education')}</Nav.Link>
                        <Nav.Link as={HashLink} to="/contact#social">{t('navbar.contact')}</Nav.Link>
                    </Nav>
                </div>
                <Nav className="ml-auto social-links">
                    <div className="social-links">
                        <Nav.Link href="https://www.linkedin.com/in/matthieu-raynaud-de-fitte/" target="_blank"><i className="fab fa-linkedin"></i></Nav.Link>
                        <Nav.Link href="https://github.com/Hellfire01" target="_blank"><i className="fab fa-github"></i></Nav.Link>
                        <Nav.Link href="https://stackoverflow.com/users/4817370/matthieu-raynaud-de-fitte" target="_blank"><i className="fab fa-stack-overflow"></i></Nav.Link>
                        <Image onClick={() => changeLanguage('en')} src={EnImg} />
                        <Image onClick={() => changeLanguage('fr')} src={FrImg} />
                    </div>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}

export default Header;
