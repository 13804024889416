import React, { useEffect, useState } from 'react';
import './stack_overflow.css';

interface BadgeCounts {
    bronze: number;
    silver: number;
    gold: number;
}

interface UserData {
    profile_image: string;
    reputation: number;
    badge_counts: BadgeCounts;
}

function StackOverflowUserCard() {
    const [userData, setUserData] = useState<UserData | null>(null);

    useEffect(() => {
        fetch('https://api.stackexchange.com/2.2/users/4817370?order=desc&sort=reputation&site=stackoverflow')
            .then(response => response.json())
            .then(data => setUserData(data.items[0]))
            .catch(error => console.error('Error:', error));
    }, []);

    if (!userData) return null;

    return (
        <div className="user-card">
            <img src={userData.profile_image} alt="User Avatar" className="user-avatar" />
            <div className="user-details">
                <div className="user-reputation">{userData.reputation}</div>
                <div className="user-badges">
                    <div className="badge gold-badge">
                        <div className="badge-circle"></div>
                        {userData.badge_counts.gold}
                    </div>
                    <div className="badge silver-badge">
                        <div className="badge-circle"></div>
                        {userData.badge_counts.silver}
                    </div>
                    <div className="badge bronze-badge">
                        <div className="badge-circle"></div>
                        {userData.badge_counts.bronze}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default StackOverflowUserCard;
