import "./presentation_quote_1.css";
import {useTranslation} from "react-i18next";
import { Container, Row, Col} from "react-bootstrap";

function PresentationQuote1() {
    const { t } = useTranslation('presentation');

    return <Container className="quote-container1">
        <Row>
            <Col xs={1}/>
            <Col xs={10} className="quote1">
                <h1 className="quote-text1">"{t('presentation.presentation_quote_1')}"</h1>
            </Col>
            <Col xs={1}/>
        </Row>
        <Row>
            <Col xs={1}/>
            <Col xs={10} className="quote-author1">
                <h1 className="quote-author-text1">{t('presentation.presentation_quote_1_author')}</h1>
            </Col>
            <Col xs={1}/>
        </Row>
    </Container>
}

export default PresentationQuote1;
