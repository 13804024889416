import './App.css';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Education from './components/education_page/education';
import Presentation from './components/presentation_page/presentation';
import Experiences from "./components/experiences_page/experiences";
import Layout from './components/layout/layout';
import NoPage from "./components/nopage/nopage";
import ThalesExperience from "./components/experiences_page/thales/thales_experience";
import SocieteGeneraleExperience from "./components/experiences_page/societe_generale/societe_generale_experience";
import EoxisExperience from "./components/experiences_page/eoxis/eoxis_experience";
import SelfeeExperience from "./components/experiences_page/selfee/selfee_experience";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<Presentation />} />
                    <Route path="skills" element={<Presentation />} />
                    <Route path="contact" element={<Presentation />} />
                    <Route path="experiences/eoxis" element={<EoxisExperience />} />
                    <Route path="experiences/societe_generale" element={<SocieteGeneraleExperience />} />
                    <Route path="experiences/thales" element={<ThalesExperience />} />
                    <Route path="experiences/selfee" element={<SelfeeExperience />} />
                    <Route path="experiences" element={<Experiences />} />
                    <Route path="education" element={<Education />} />
                    <Route path="*" element={<NoPage />} />
                </Route>
            </Routes>
        </BrowserRouter>
    )
}

export default App;
