import './social_links.css'
import {Col, Container, Row, Image, Nav} from "react-bootstrap";
import Github from '../../../assets/img/contact/GitHub_Logo.png'
import LinkedIn from '../../../assets/img/contact/LinkedIn-Logo.png'
import StackOverflow from '../../../assets/img/contact/so-logo.png'
import {useTranslation} from "react-i18next";
import StackOverflowUserCard from "./stack_overflow/stack_overflow";

function SocialLinks() {
    const { t } = useTranslation('presentation');

    return <Container id={"social"}>
        <Row>
            <h1 className={"social-links-title"}>{t("presentation.social_links_title")}</h1>
        </Row>
        <Row>
            <Col>
                <div className={"contact-text-align-center"}>
                    <h5 className={"social-small-title"}>{t("presentation.social_links_1")}</h5>
                    <h5>{t("presentation.social_links_2")}</h5>
                </div>
                <Container>
                    <Row>
                        <Col lg={4} className={"contact-logo-container"}>
                            <Nav.Link href="https://github.com/Hellfire01">
                                <Image className={"contact-logo"} src={Github}/>
                            </Nav.Link>
                        </Col>
                        <Col lg={4} className={"contact-logo-container"}>
                            <Nav.Link href="https://stackoverflow.com/users/4817370/matthieu-raynaud-de-fitte">
                                <Container className={"contact-logo-sub-container"}>
                                    <Row>
                                        <Image className={"contact-logo"} src={StackOverflow}/>
                                    </Row>
                                    <Row>
                                        <StackOverflowUserCard />
                                    </Row>
                                </Container>
                            </Nav.Link>
                        </Col>
                        <Col lg={4} className={"contact-logo-container"}>
                            <Nav.Link href="https://www.linkedin.com/in/matthieu-raynaud-de-fitte/">
                                <Image className={"contact-logo"} src={LinkedIn}/>
                            </Nav.Link>
                        </Col>
                    </Row>
                </Container>
            </Col>
        </Row>
    </Container>
}

export default SocialLinks;
