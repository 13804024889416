import React from "react";
import "./youtube_embeded.css";


const YoutubeEmbed = ({
        embedId,
        className
    } : {
        embedId: string
        className?: string
    }) => (
    <div className={`video-responsive ${className}`}>
        <iframe
            src={`https://www.youtube.com/embed/${embedId}`}
            allowFullScreen
            title="Embedded youtube"
        />
    </div>
);

export default YoutubeEmbed;
