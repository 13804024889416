import './presentation_text.css';
import {Image, Container, Row, Col} from "react-bootstrap";
import MeImg from "../../../assets/img/photos/profile-picture.jpg";
import {useTranslation} from "react-i18next";

function PresentationText() {
    const { t } = useTranslation('presentation');

    return (
        <Container className="presentation-section">
            <Row>
                <Col xs={12} md={5}>
                    <div className="image-container">
                        <Image src={MeImg} className="me-image w-100" />
                    </div>
                </Col>
                <Col xs={12} md={7}>
                    <div className="presentation-text-container">
                        <h1 className="presentation-title">{t('presentation.presentation_title')}</h1>
                    </div>
                    <p>{t('presentation.presentation_text1')}</p>
                    <p>{t('presentation.presentation_text2')}</p>
                    <p>{t('presentation.presentation_text3')}</p>
                    <p>{t('presentation.presentation_text4')}</p>
                </Col>
            </Row>
        </Container>
    );
}

export default PresentationText;
